import React, { useContext, useEffect } from 'react';
import {
    BrowserRouter as Router,
    createRoutesFromChildren,
    matchRoutes,
    Route,
    Routes,
    useLocation,
    useNavigate,
    useNavigationType,
} from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import config from './util/config';
import mixpanel from 'mixpanel-browser';
import ChatMain from './pages/chat/ChatMain';
import { Return } from './pages/confirmation/Return';
import VerifyUser from './pages/confirmation/VerifyUser';
import VerifyEmail from './pages/confirmation/VerifyEmail';
import ResetPassword from './pages/confirmation/ResetPassword';
import PaywallModal from './pages/modals/PaywallModal';
import GeneralProvider, { GeneralContext } from './context/GeneralContext';
import { QueryClientProvider } from '@tanstack/react-query';
import SettingsModal from './pages/modals/SettingsModal';
import useUser from './context/useUser';
import { DeleteConversationModal } from './pages/modals/DeleteConversationModal';
import LoginModal from './pages/modals/LoginModal';
import LoginPage from './pages/LoginPage';
import NewLandingPage from '@/pages/NewLandingPage';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'moment-timezone';
import ThemeColorMeta from '@/util/headerTheme';
import SettingsPage from '@/pages/Settings';
import DashboardPage from '@/pages/modals/DashboardPage';
import Guest from '@/pages/Guest';
import { PrivacyPolicy } from '@/pages/PrivacyPolicy';
import useTimezone from '@/hooks/useTimezone';
import queryClient from '@/util/queryClient';
import { Terms } from '@/pages/Terms';
import { PaymentConfirmationModal } from '@/pages/modals/PaymentConfirmationModal';
import Onboarding from '@/pages/modals/Onboarding';
import Hotline from '@/pages/Hotline';
import CallReport from './pages/CallReport';
import { CallHistoryItem } from '@/pages/CallHistoryItem';
import { CallHistoryList } from '@/pages/CallHistoryList';
import Responses from '@/pages/Responses';
import { ResponsesList } from '@/pages/ResponsesList';
import ResponseItem from '@/pages/ResponseItem';
import NewestLandingPage from '@/pages/NewestLandingPage';

mixpanel.init(config.mixpanelToken, {
    debug: config.environment !== 'development',
    track_pageview: true,
    persistence: 'localStorage',
    ignore_dnt: config.environment === 'development',
});

console.debug('Environment: ', config.environment);

Sentry.init({
    dsn: 'https://f34d35f895b0b8a411a4df78418868e8@o4505834978410496.ingest.sentry.io/4506040046387200',
    release: config.appVersion,
    environment: config.environment,
    integrations: [
        new Sentry.BrowserTracing({
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            ),
        }),
        new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        config.localhost,
        /^https:\/\/api\.subi\.ai/,
        /^https:\/\/staging-api\.subi\.ai/,
    ],

    // Capture Replay for none of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
});

// window.dataLayer.push({
//     event: 'set_hostname',
//     hostname: window.location.hostname,
// });

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <GoogleOAuthProvider clientId={config.googleClientId}>
                <GeneralProvider>
                    <AppRouter />
                    <ReactQueryDevtools initialIsOpen={false} />
                </GeneralProvider>
            </GoogleOAuthProvider>
        </QueryClientProvider>
    );
}

const AppRouter = () => {
    const context = useContext(GeneralContext);
    const { user } = useUser();
    useTimezone(user);

    return (
        <div className={`App ${context?.dark} `}>
            <ThemeColorMeta
                color={context && context?.dark ? '#141718' : '#ffffff'}
            />
            <div
                style={{ zIndex: 99999 }}
                className={`absolute top-2 left-2 w-4 h-4 rounded-full bg-red-500 ${context?.isOffline !== true && 'hidden'}`}
                title={`Connection is ${context?.isOffline ? 'Offline' : 'Online'}`}
            />
            <Router>
                <div className="flex flex-row w-100 bg-lightGray dark:bg-lightBlack dark:text-white ">
                    {/*{context?.width >= 768*/}
                    {/*    ? user && user.paywall !== true && <SidebarDesktop />*/}
                    {/*    : context.mobileSidebar && (*/}
                    {/*          <div*/}
                    {/*              className="flex flex-col bg-white dark:bg-black"*/}
                    {/*              style={{ width: '80vw' }}*/}
                    {/*          >*/}
                    {/*              <MobileNavigationMenu />*/}
                    {/*          </div>*/}
                    {/*      )}*/}
                    <div
                        className={`flex-1 `}
                        style={{
                            height: context?.height,
                            overflow: 'hidden',
                        }}
                    >
                        {context.mobileSidebar && context?.width < 768 && (
                            <div
                                onClick={e => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    context.setMobileSidebar(false);
                                }}
                                style={{
                                    height: context?.height,
                                    overflow: 'hidden',
                                    width: '20vw',
                                }}
                                className="absolute bg-systemBlack  z-50 opacity-20"
                            />
                        )}
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <PrivateRoute user={user}>
                                        <Hotline />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/join"
                                element={
                                    <PublicRoute user={user}>
                                        <Onboarding />
                                    </PublicRoute>
                                }
                            />
                            <Route
                                path="/responses"
                                element={
                                    <PrivateRoute user={user}>
                                        <Responses />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/responses/attention"
                                element={
                                    <PrivateRoute user={user}>
                                        <ResponsesList needsAttention={true} />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/responses/all"
                                element={
                                    <PrivateRoute user={user}>
                                        <ResponsesList needsAttention={false} />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/responses/:responseId"
                                element={
                                    <PrivateRoute user={user}>
                                        <ResponseItem />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/chat/:conversationId"
                                element={
                                    <PrivateRoute user={user}>
                                        <ChatMain />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/request/:conversationId"
                                element={
                                    <PrivateRoute user={user}>
                                        <ChatMain />
                                    </PrivateRoute>
                                }
                            />
                            <Route path="/return" element={<Return />} />
                            <Route
                                path="/settings"
                                element={
                                    <PrivateRoute user={user}>
                                        <MobileRoute width={context?.width}>
                                            <SettingsPage />
                                        </MobileRoute>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/dashboard"
                                element={
                                    <PrivateRoute user={user}>
                                        <MobileRoute width={context?.width}>
                                            <DashboardPage />
                                        </MobileRoute>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path={'/call/'}
                                element={<CallHistoryList />}
                            />
                            <Route
                                path={'/help/'}
                                element={<CallHistoryList />}
                            />

                            <Route
                                path={'/call/:callId'}
                                element={<CallHistoryItem />}
                            />
                            <Route
                                path="/call-report"
                                element={<CallReport />}
                            />
                            <Route path="/login" element={<LoginPage />} />
                            <Route
                                path="/privacy"
                                element={<PrivacyPolicy />}
                            />
                            <Route path="/terms" element={<Terms />} />
                            <Route
                                path="/su"
                                element={<LoginPage register={true} />}
                            />
                            <Route
                                path="/verify-account"
                                element={<VerifyUser />}
                            />
                            <Route
                                path="/verify-email"
                                element={<VerifyEmail />}
                            />
                            <Route
                                path="/reset-password"
                                element={<ResetPassword />}
                            />
                            {/* Keep the guest route at the bottom */}
                            <Route path="/:guestLink" element={<Guest />} />
                        </Routes>
                    </div>

                    {context?.openModal === 'paywall' && <PaywallModal />}
                    {context?.openModal === 'Log in' && <LoginModal />}
                    {(context?.openModal === 'settings' ||
                        context?.openModal === 'customization' ||
                        context?.openModal === 'integrations' ||
                        context?.openModal === 'membership') && (
                        <SettingsModal />
                    )}
                    {context?.openModal === 'confirmation' && (
                        <PaymentConfirmationModal />
                    )}

                    {context?.deleteOpen !== '' && (
                        <DeleteConversationModal
                            deleteOpen={context?.deleteOpen}
                            setDeleteOpen={context?.setDeleteOpen}
                        />
                    )}
                </div>
            </Router>
        </div>
    );
};

function PrivateRoute({ user, children }) {
    if (user) {
        if (user.email?.includes('@subi-phone.ai')) {
            return <NewestLandingPage />;
        }
        if (user.paywall === true) {
            return <PaywallModal open={true} />;
        }
        return children;
    } else {
        return <NewestLandingPage />;
    }
}

function PublicRoute({ user, children }) {
    const navigate = useNavigate();
    if (user) {
        if (user.email?.includes('@subi-phone.ai') === true) {
            return children;
        } else {
            return <Hotline />;
        }
    } else {
        return children;
    }
}

function MobileRoute({ width, children }) {
    const navigate = useNavigate();
    if (width < 768) {
        return children;
    } else {
        navigate('/');
    }
}

function AdminRoute({ user, children }) {
    console.log('USER (Adm)', user);

    if (user) {
        if (user.is_superuser) {
            return children;
        } else {
            return <NewLandingPage />;
        }
    } else {
        return <NewLandingPage />;
    }
}

export default App;
