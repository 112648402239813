import config from './config';
import { format, isThisWeek, isToday } from 'date-fns';
import { v4 as uuid } from 'uuid';

function getChatIdFromUrl() {
    let pathParts = window?.location?.pathname?.split('/chat/');
    if (pathParts.length !== 2) {
        pathParts = window?.location?.pathname?.split('/request/');
    }
    // console.log(
    //     'window pathname',
    //     window?.location?.pathname,
    //     pathParts.length,
    //     pathParts,
    // );
    const chatId = pathParts.length === 2 ? pathParts[1] : false;

    return chatId;
}

function getAiFromConvo(conversation) {
    let aiParticipant = conversation?.participants?.find(
        participant => participant?.user?.user_type === 'ai',
    );

    if (aiParticipant && aiParticipant?.length > 1) {
        aiParticipant = aiParticipant[0];
    }

    if (aiParticipant && aiParticipant?.user) {
        return aiParticipant.user;
    } else {
        return {
            display_name: 'Subi.ai',
            avatar_path: '',
        };
    }
}

export function correctUrlForEnvironment(url) {
    let envUrl = url.replace(
        /https?:\/\/(localhost:8000|api.subi.ai)/g,
        config.apiUrl,
    );
    return envUrl;
}

export function formatTimestamp(timestamp) {
    try {
        let formattedDate = format(timestamp, 'MM/dd');
        if (isThisWeek(timestamp)) {
            formattedDate = format(timestamp, 'iiii');
        }
        if (isToday(timestamp)) {
            formattedDate = format(timestamp, 'p');
        }
        return formattedDate;
    } catch (error) {
        // console.debug('Invalid time value:', error);
        return 'Invalid Date';
    }
}

function resizeCardUrl(cardUrl) {
    cardUrl = cardUrl?.replace('a4astatic', 'subistatic');

    return cardUrl?.replace(
        'https://subistatic.subi.ai/cards/',
        'https://subistatic.subi.ai/cdn-cgi/image/height=600,format=auto/cards/',
    );
}

export { getChatIdFromUrl, getAiFromConvo, resizeCardUrl };

export async function validatePassword(
    password,
    passwordConfirm,
    setError,
    onSuccess,
) {
    setError('');

    if (password !== passwordConfirm) {
        setError('Passwords do not match');
        return false;
    }
    if (password.length < 8) {
        setError('Password must be at least 8 characters');
        return false;
    }
    if (password.length >= 64) {
        setError('Password must be less than 64 characters');
        return false;
    }
    setError('');
    onSuccess();
    return true;
}

export function validateEmail(email, onError = () => {}) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const validate = regex.test(email);
    if (validate === false) {
        onError();
    }
    return validate;
}

export function isValidPhoneNumber(phoneNumber) {
    // Regular expression for validating phone numbers
    const regex =
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    // Test the phone number against the regex
    return regex.test(phoneNumber);
}

export function checkUserEmptyOrGuest(user) {
    return !user || user.is_guest;
}

export function createOptimisticMessage(
    conversationId = uuid(),
    user,
    content,
    guestOwner = undefined,
) {
    const messageId = uuid();

    const sentAt = new Date().toISOString();

    if (guestOwner) {
        return {
            id: messageId,
            type: 'human',
            conversation_id: conversationId,
            sender: { ...user, user_type: user.user_type },
            sent_at: sentAt,
            guest_mode_owner_id: guestOwner.user_id,
            message: {
                type: 'human',
                data: {
                    content: content,
                    additional_kwargs: {
                        sent_at_override: sentAt,
                    },
                    response_metadata: {},
                    type: 'human',
                    example: false,
                },
            },
        };
    }
    return {
        id: messageId,
        type: 'human',
        conversation_id: conversationId,
        sender: { ...user, user_type: user.user_type },
        sent_at: sentAt,
        message: {
            type: 'human',
            data: {
                content: content,
                additional_kwargs: {
                    sent_at_override: sentAt,
                },
                response_metadata: {},
                type: 'human',
                example: false,
            },
        },
    };
}
